<template>
  <div>
    Audiences page
  </div>
</template>

<script>
export default {
  created() {
    this.hideLoading()
  },
}
</script>

<style>

</style>
